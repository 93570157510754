












































import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import ContactsList from '@/components/ContactsList.vue';
import {Action, Getter} from 'vuex-class';
import {AssociateAccount, BusinessContact} from '@/domain/model/types';
import {applicationStore} from '@/store/modules/application';
import {settingsStore} from '@/store/modules/settings';
import SearchView from '@/components/SearchView.vue';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import {profileStore} from '@/store/modules/profile';
import {businessStore} from '@/store/modules/business/businessStore';

@Component({
    name: 'dialog-add-appointment-assistant',
    components: {AvatarWithStatus, SearchView, BaseDialog}
})
export default class DialogAddAppointmentAssistant extends Vue {
    @Prop() value!: boolean;

    loading: boolean = false;
    associates: { data: any, selected: boolean }[] = [];
    associatesOriginal: { data: any, selected: boolean }[] = [];

    get show() {
        return this.value;
    }

    set show(value: boolean) {
        this.$emit('input', value);
    }

    get businessId() {
        return applicationStore.businessId;
    }

    get t2bUser() {
        return profileStore.t2bUser;
    }

    get assistants() {
        return profileStore.assistants
    }

    get filterIDs() {
        const ids = this.assistants.map((item) => item.id);
        return [this.t2bUser!.id, ...ids];
    }

    get associateItems() {
        return this.associates
    }

    get selectedAssociates() {
        return this.associates.filter((item) => item.selected).map((item) => item.data)
    }

    photoUrl(associate) {
        return associate.photoUrl && associate.photoUrl.normal;
    }

    isOnline(associate) {
        const status = associate.status;
        return status && status.online;
    }

    searchAssociates(term) {
        if (term === null || term === '') {
            this.associates = this.associatesOriginal;
            return;
        }
        const searchString = term.toLowerCase();
        this.associates = this.associatesOriginal.filter((item) => {
            return item.data.fullName.toLowerCase().startsWith(searchString);
        });
    }

    onAssociateSelected(associate) {
        // const selected = this.associates.find((item) => item.selected);
        // if (selected) {
        //     const indexOf = this.associates.indexOf(selected);
        //     selected.selected = false;
        //     this.associates.splice(indexOf, 1, selected);
        // }
        // if (selected && selected.data.id === associate.data.id) {
        //     return;
        // }
        associate.selected = !associate.selected;
        const found = this.associates.find((item) => item.data.id === associate.data.id);
        if (found) {
            this.associates.splice(this.associates.indexOf(found), 1, associate);
        }
    }

    async onAddSelectedAssistants() {
        if (!this.selectedAssociates.length) {
            console.log('No associates selected');
            return;
        }
        this.loading = true;
        try {
            await profileStore.addAppointmentAssistants(this.selectedAssociates);
            this.show = false;
        } catch (err) {
            console.log(`Add assistants. error: ${err}`);
        }
        this.loading = false;
    }

    beforeCreated() {
    }

    async created() {
        await businessStore.loadAssociates();

        const filterFunc = (item) => !this.filterIDs.includes(item.id);
        const mapFunc = (item) => {
            return {data: item, selected: false};
        };
        this.associates = businessStore.associates.filter(filterFunc).map(mapFunc);
        this.associatesOriginal = Object.assign([], this.associates);
    }
}
