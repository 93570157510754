




























import {Component, Prop} from 'vue-property-decorator';
import {Action} from 'vuex-class';
import {copyTextToClipboard} from '@/utils/helpers';
import {applicationStore} from '@/store/modules/application';
import AssignedContactCard from '@/components/profile/AssignedContactCard.vue';
import {directoryStore} from '@/store/modules/directory/directoryStore';
import {profileStore} from '@/store/modules/profile';
import {mixins} from 'vue-class-component';
import ContainerCalc from '@/components/mixins/ContainerCalc';

@Component({
  name: 'profile-info-assignment',
  components: {
    AssignedContactCard
  }
})
export default class ProfileInfoAssignment extends mixins(ContainerCalc) {
  @Prop({type: Object}) user

  @Action shareContactLink;

  get isAdmin() {
    return applicationStore.isAdmin
  }

  get userId() {
    return this.user?.id || profileStore.t2bUser?.id || ''
  }

  get assignedContacts() {
    return directoryStore.allContacts.filter((item) => item.associateIDs?.includes(this.userId))
  }

  get contacts() {
    return this.assignedContacts.filter((contact) => contact.type === 1)
  }

  get groupContacts() {
    return this.assignedContacts.filter((contact) => contact.type === 2)
  }

  async onShareContact(contactId: string) {
    try {
      const shortLink = await this.shareContactLink(contactId);
      await copyTextToClipboard(shortLink);
      this.$emit('on-info', 'Contact link copied to clipboard');
    } catch (err: any) {
      console.log(`share contact. error: ${err}`);
      this.$emit('on-error', err.message);
    }
  }

  async onDeleteContact(contactId: string) {
    console.log(`onDeleteBackingContact contactId: ${contactId}`);
    try {
      await directoryStore.deleteContact2(contactId);
    } catch (err) {
      console.log(`onDeleteBackingContact error: ${err}`);
    }
  }

  onOpenContactInfo(contactId: string) {
    this.$router.push({name: 'contact-info', params: {contactId}})
  }

  mounted() {
    this.calcHeight(this.$refs.assignmentsContainer)
  }
}
