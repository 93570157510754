





















































































































import {Component} from 'vue-property-decorator';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import {copyTextToClipboard, twoChars} from '@/utils/helpers';
import ContactInfoCard from '@/components/ContactInfoCard.vue';
import VcSwitch from '@/components/VCSwitch.vue';
import ContactInfoGeneral from '@/components/ContactInfoGeneral.vue';
import ContactInfoAssignment from '@/components/ContactInfoAssignment.vue';
import {applicationStore} from '@/store/modules/application';
import DialogEditProfile from '@/components/profile/DialogEditProfile.vue';
import ProfileInfoGeneral from '@/components/profile/ProfileInfoGeneral.vue';
import ProfileInfoWorkingStatus from '@/components/profile/ProfileInfoWorkingStatus.vue';
import {profileStore} from '@/store/modules/profile';
import ProfileInfoAssignment from '@/components/profile/ProfileInfoAssignment.vue';
import ProfileInfoPermissions from '@/components/profile/ProfileInfoPermissions.vue';
import PopupMenu from '@/components/PopupMenu.vue';
import Notifications from '@/components/mixins/Notifications';
import {mixins} from 'vue-class-component';
import {Action} from 'vuex-class';

@Component({
  name: 'profile-overview',
  components: {
    PopupMenu,
    ProfileInfoPermissions,
    ProfileInfoAssignment,
    ProfileInfoWorkingStatus,
    ProfileInfoGeneral,
    DialogEditProfile,
    ContactInfoAssignment,
    ContactInfoGeneral,
    VcSwitch, ContactInfoCard, AvatarWithStatus
  }
})
export default class ProfileOverview extends mixins(Notifications) {
  showEditProfileDialog: boolean = false;
  showDeleteProfile: boolean = false;
  selectedTab: number = 0;
  tabs: string[] = ['General', 'Working Status', 'Assignments', 'Permissions', 'Statistics']

  @Action('clearSetupState') clearSetupState;

  get t2bUser() {
    return profileStore.t2bUser;
  }

  get menu() {
    return [
      {
        index: 0,
        title: 'Disable'
      },
      {
        index: 1,
        title: 'Delete',
        alert: true
      }
    ];
  }

  get title(): string {
    return this.t2bUser?.fullName || 'Associate';
  }

  get subtitle(): string {
    return this.t2bUser?.position || 'Associate';
  }

  get photoUrl(): string {
    return this.t2bUser?.photoUrl?.normal || ''
  }

  get abbreviation() {
    return twoChars(this.title);
  }

  get rating() {
    return (this.t2bUser?.stats?.avgRating || 0.0).toFixed(2);
  }

  get reviewsCount() {
    return this.t2bUser?.stats?.cases || 0;
  }

  async signOut() {
    this.clearSetupState();
    await applicationStore.signOut();
    await this.$router.push({path: '/auth/sign-in'});
  }

  onTabChanged(index: number) {
    this.$router.push({name: 'profile-overview', query: {tab: this.tabs[index]}});
  }

  onMenuItemClicked(menuItem) {
    switch (menuItem.index) {
      case 0: {

        break;
      }
      case 1: {
        this.showDeleteProfile = true;
        break;
      }
      default:
    }
  }

  async shareProfile() {
    if (!this.t2bUser) {
      return;
    }
    try {
      const shortLink = await profileStore.shareProfileLink(this.t2bUser.id);
      await copyTextToClipboard(shortLink);
      this.showInfoSnack('Copied to clipboard');
    } catch (err: any) {
      console.log(`share contact. error: ${err}`);
      this.$emit('on-error', err.message);
    }
  }

  onChangesSaved() {
    this.showInfo('Changes saved');
  }

  showInfoSnack(message) {
    this.showInfo(message);
  }

  async created() {
    this.selectedTab = this.tabs.indexOf(this.$route.query.tab as string)
    profileStore.startListening()
  }

  async updated() {
    this.selectedTab = this.tabs.indexOf(this.$route.query.tab as string)
    console.log('PROFILE_OVERVIEW:: updated');
    try {
      // await this.loadBackingContacts(this.selectedContact);
    } catch (err) {
      console.log(`load assignment error: ${err}`);
    }
  }

  beforeDestroy() {
    profileStore.releaseListeners()
  }
}
