







































import {Component, Prop, Vue} from 'vue-property-decorator';
import {twoChars} from '@/utils/helpers';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import ContactInfoCard from '@/components/ContactInfoCard.vue';
import {AssociateAccount} from '@/domain/model/types';
import {applicationStore} from '@/store/modules/application';
import PopupMenu from '@/components/PopupMenu.vue';

@Component({
  name: 'assigned-assistant-card',
  components: {PopupMenu, ContactInfoCard, AvatarWithStatus}
})
export default class AssignedAssistantCard extends Vue {
  @Prop({
    default: function() {
      return {};
    }
  }) associate!: AssociateAccount;

  showDeleteAssistant: boolean = false;

  menu: any[] = [
    {
      title: 'Remove',
      alert: true,
      index: 0
    }
  ]

  get isAdmin() {
    return applicationStore.isAdmin
  }

  get photoUrl() {
    return this.associate.photoUrl && this.associate.photoUrl.normal;
  }

  get online() {
    return this.associate.status && this.associate.status.online;
  }

  get name() {
    return this.associate.fullName;
  }

  get position() {
    return this.associate.position || this.associate.description || 'Associate';
  }

  get abbreviation() {
    return twoChars(this.name);
  }

  onMenuItemClicked(menuItem) {
    switch (menuItem.index) {
      case 0: {
        this.showDeleteAssistant = true;
        break;
      }
      default:
    }
  }
}
