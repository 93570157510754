













































import {Component, Vue} from 'vue-property-decorator';
import ContactInfoCard from '@/components/ContactInfoCard.vue';
import {Action} from 'vuex-class';
import VcSwitch from '@/components/VCSwitch.vue';
import {formatMuted, timeToFrom, phoneFormat} from '@/utils/helpers';
import DialogNotificationSettings from '@/components/profile/DialogNotificationSettings.vue';
import {profileStore} from '@/store/modules/profile';
import DialogMuteTimeSettings from '@/components/profile/DialogMuteTimeSettings.vue';

@Component({
  name: 'profile-info-general',
  components: {DialogMuteTimeSettings, DialogNotificationSettings, VcSwitch, ContactInfoCard},
  filters: {
    formatMuted
  }
})
export default class ProfileInfoGeneral extends Vue {
  showMuteChatDialog: boolean = false
  mutedInterval: any = undefined

  @Action changeAdminRole;

  get associate() {
    return profileStore.t2bUser!;
  }

  get mutedFor() {
    return this.associate?.doNotDisturb
  }

  get admin() {
    return this.associate?.roles?.admin || this.associate?.admin;
  }

  set admin(value) {
    try {
      this.changeAdminRole({id: this.associate!.id, admin: value});
      console.log(`set admin. value: ${value}`);
    } catch (err) {
      console.log(`set admin. error: ${err}`);
    }
  }

  get email() {
    return this.associate?.email;
  }

  set email(email) {
    this.associate!.email = email;
  }

  get position() {
    return this.associate?.position;
  }

  set position(position) {
    this.associate!.position = position;
  }

  get phone() {
    return phoneFormat(this.associate?.phoneNumber);
  }

  set phone(phone) {
    this.associate!.phoneNumber = phone;
  }

  async onSaveNotificationSettings(period: number = 0) {
    await profileStore.muteProfile(period)
  }

  onToggle() {
    if (!!this.mutedFor) {
      this.onSaveNotificationSettings()
      return
    }
    this.showMuteChatDialog = true
  }

  counterMutedFor():Boolean {
    if (!this.mutedFor) {
      return false
    }
    const nowMillis = new Date().getTime()
    const targetMillis = this.mutedFor.toMillis();
    const difHours = (targetMillis - nowMillis) / (60 * 60 * 1000);
    if (difHours <= 0) {
      return false
    }
    return difHours <= 24*365 ? true : false
  }

  launchTimer() {
    if (this.counterMutedFor() && typeof this.mutedInterval !== 'number') {
      this.mutedInterval = setInterval(() => {
        this.$forceUpdate()
      }, 1000)
    } else if(!this.counterMutedFor() && typeof this.mutedInterval === 'number') {
      clearInterval(this.mutedInterval)
      this.mutedInterval = undefined
      this.onSaveNotificationSettings(0)
    } else if (!this.counterMutedFor() && this.mutedFor) {
      this.onSaveNotificationSettings(0)
    }
  }

  updated() {
    this.launchTimer()
  }

  created() {
    this.launchTimer()
  }

  beforeDestroy() {
    if (typeof this.mutedInterval === 'number') {
      clearInterval(this.mutedInterval)
      this.mutedInterval = undefined
    }
  }
}
